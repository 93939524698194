.container{
  margin-right: auto;
  margin-left:  auto;

  max-width: 1230px;

  padding-right: 15px;
  padding-left:  15px;

}

