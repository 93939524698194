.privacy {
	margin-bottom: 50px;
	line-height: 1.4;
	.container {
		padding: 20px;
		background: white;
		border-radius: 16px;
		//box-shadow: 0;
		box-shadow: 0 20px 50px rgba(59, 43, 91, 0.1);
		@media (min-width: 1024px) {
			padding: 40px;
		}
	}

	[data-custom-class='body'],
	[data-custom-class='body'] * {
		background: transparent !important;
	}
	[data-custom-class='title'],
	[data-custom-class='title'] * {
		font-family: Arial !important;
		font-size: 26px !important;
		color: #000000 !important;
	}
	[data-custom-class='subtitle'],
	[data-custom-class='subtitle'] * {
		font-family: Arial !important;
		color: #595959 !important;
		font-size: 14px !important;
	}
	[data-custom-class='heading_1'],
	[data-custom-class='heading_1'] * {
		font-family: Arial !important;
		font-size: 19px !important;
		color: #000000 !important;
	}
	[data-custom-class='heading_2'],
	[data-custom-class='heading_2'] * {
		font-family: Arial !important;
		font-size: 17px !important;
		color: #000000 !important;
	}
	[data-custom-class='body_text'],
	[data-custom-class='body_text'] * {
		color: #595959 !important;
		font-size: 14px !important;
		font-family: Arial !important;
	}
	[data-custom-class='link'],
	[data-custom-class='link'] * {
		color: #3030f1 !important;
		font-size: 14px !important;
		font-family: Arial !important;
		word-break: break-word !important;
	}
	ul {
		list-style-type: square;
	}
	ul > li > ul {
		list-style-type: circle;
	}
	ul > li > ul > li > ul {
		list-style-type: square;
	}
	ol li {
		font-family: Arial;
	}
}
