.addition {
	padding: 40px 0;
	// background: linear-gradient(
	// 	106.76deg,
	// 	rgba(255, 255, 255, 0.8) -11.74%,
	// 	rgba(233, 242, 252, 0.8) 116.4%
	// );
	background: url("../img/addition-bg-d.png")  top center;
	background-size: contain;
	background-repeat: no-repeat;
	@media (min-width: 768px) {
		// background: url('../img/top-footer-bg.png');
		background-size: contain;
		position: relative;
	}
	// &:before {
	// 	content: '';
	// 	background: url('../img/top-footer-bg.png');
	// 	background-size: cover;
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: -100%;
	// 	height: 100%;
	// 	min-height: 100vh;
	// 	z-index: -1;
	// 	background-position: bottom;
	// 	@media (min-width: 768px) {
	// 		background: url('../img/top-footer-bg.png') !important;
	// 		background-position-x: 0%;
	// 		background-position-y: 0%;
	// 		background-repeat: repeat;
	// 		background-size: auto;
	// 		background-size: cover !important;
	// 		background-position: center bottom !important;
	// 	}
	// }

	&__title {
		font-family: Rubik;
		font-weight: 500;
		font-size: 28px;
		line-height: 33px;

		color: #313131;
		@media (min-width: 768px) {
			font-size: 48px;
			line-height: 57px;
			text-align: center;
		}
	}
	&__list {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		@media (min-width: 768px) {
			flex-direction: row;
			align-items: flex-start;
		}
	}
	&__item {
		margin-top: 15px;
	}
	&__item-title {
		font-family: Rubik;
		font-weight: 300;
		font-size: 24px;
		line-height: 28px;
		text-align: center;
		color: #313131;
		max-width: 300px;
		margin: 15px auto;
		max-width: 270px;
		@media (min-width: 768px) {
			font-weight: normal;
			font-size: 24px;
			line-height: 28px;
		}
	}
	&__img {
		max-width: calc(100% + 30px);
		width: calc(100% + 30px);
		margin: -15px;
		display: block;
		@media (min-width: 425px) {
			margin: -15px 0;
			max-width: 360px;
			width: 100%;
		}
		@media (min-width: 768px) {
			margin: -10px 0 -25px 0;
		}
		&--mobile-none {
			display: none;
			@media (min-width: 768px) {
				display: block;
			}
		}
		&--mobile-only {
			@media (min-width: 768px) {
				display: none;
			}
		}
	}
}
