.direction-rtl{
  direction: rtl;
  .header{
    direction: ltr;
    //&__logo-icon{
    //  margin: 0 0 0 20px;
    //}
    //&__link-icon{
    //  margin: 0 0 0 10px;
    //}
    //&__lang{
    //  @media (min-width: 900px) {
    //    text-align: left;
    //  }
    //}
    //&__contact-link-text{
    //  direction: ltr;
    //}

  }
  .about{
    &__img--mobile-only{
      margin: -100px -55% 0 0;
    }
    &__reasons-item{
      @media (min-width: 768px) {
      margin-left: 60px;
      }
      @media (min-width: 1024px) {
        margin-left: 120px;

      }
    }
    &__reasons-text{
      max-width: 300px;
    }
  }
  .features{
    &__item{
      @media (min-width: 1024px) {
        &:nth-of-type(2){
          .features__text{
            max-width: 450px;
          }
          .features__description{
            margin:  0 0 0 40px;

          }
        }
        &:nth-of-type(odd){
          .features__img{
            margin:  0 0 0 40px;
          }
        }
        &--reverse{
          flex-direction: row-reverse;
        }
      }
    }
  }
  .footer{
    direction: ltr;
    //&__logo{
    //  margin: 0 0 0 20px;
    //}
    //&__link-icon{
    //  margin: 0 0 0 10px;
    //}
    //&__app-link{
    //  &:first-of-type{
    //    margin: 0 0 0 20px;
    //  }
    //}
    //&__contact-link-text{
    //  direction: ltr;
    //}
  }
}