.intro {
	padding: 10px 0 20px;
	position: relative;

	// &:before {
	// 	content: '';
	// 	background: url('../img/home-bg-m.png');
	// 	background-size: cover;
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	top: -90px;
	// 	height: 100%;
	// 	z-index: -1;
	// 	background-position: bottom;
	// 	@media (min-width: 768px) {
	// 		background: url('../img/home-bg.png') !important;
	// 		background-position-x: 0%;
	// 		background-position-y: 0%;
	// 		background-repeat: repeat;
	// 		background-size: auto;
	// 		background-size: cover !important;
	// 		background-position: center bottom !important;
	// 	}
	// }

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	&__logo {
		width: 47px;
		display: block;
		@media (min-width: 768px) {
			display: none;
		}
	}
	&__title {
		margin-top: 22px;
		margin-bottom: 5px;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 31px;
		text-align: center;
		text-transform: uppercase;

		color: #ffffff;
		@media (min-width: 768px) {
			margin-top: 100px;
			margin-bottom: 15px;
			font-size: 52px;
			line-height: 62px;
			letter-spacing: 2px;
		}
	}
	&__text {
		font-family: Rubik;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #ffffff;
		@media (min-width: 768px) {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 20px;
		}
	}

	&__links {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 320px;
		width: 100%;
	}
	&__link {
		width: calc(50% - 10px);
	}
	&__link-img {
		width: 100%;
	}
	&__pictures {
		margin-top: 20px;

		@media (min-width: 768px) {
			margin: 50px 0 0;
		}
	}
	&__images {
		display: none;
		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
		}
	}
	&__img {
		border-radius: 24px;

		@media (min-width: 768px) {
			width: 33%;
		}
		&--mobile-only {
			border-radius: 5px;
			max-width: calc(100% + 30px);
			width: calc(100% + 30px);
			margin: 0 -15px;

			@media (min-width: 768px) {
				display: none;
			}
		}
	}
}
