.header {
	padding: 20px 0;
	background-size: cover;
	@media (min-width: 768px) {
		font-size: 18px;
		line-height: 21px;
		padding: 60px 0;
	}
	position: relative;
	&__bg {
		&-img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
			width: 100%;
			@media (min-width: 1920px) {
				max-height: 920px;
				object-fit: 100%;
			}
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&__logo {
		align-items: center;
		justify-content: center;
		font-family: Rubik;
		font-weight: 500;
		font-size: 28px;
		line-height: 120%;
		text-transform: uppercase;
		color: #ffffff;
		span {
			display: block;
		}

		display: none;
		@media (min-width: 768px) {
			display: flex;
		}
	}

	&__logo-icon {
		width: 40px;
		margin-right: 20px;
		display: block;
	}

	&__links {
		display: flex;
		align-items: center;
	}
	&__link {
		font-family: Rubik;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;

		display: flex;
		align-items: center;
		margin: 0 20px;

		color: #ffffff;
		&--mobile-none {
			display: none;
			@media (min-width: 768px) {
				display: flex;
			}
		}
		@media (min-width: 1024px) {
			font-size: 18px;
			line-height: 21px;
		}
	}
	&__link-icon {
		margin-right: 10px;
	}
	&__lang {
		@media (min-width: 900px) {
			width: 161px;
			text-align: right;
		}
	}
	&__lang-text {
		font-family: Rubik;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #ffffff;
		margin-right: 5px;
	}
}
