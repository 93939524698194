.features {
	padding: 40px 0 0;
	background: url('../img/features-bg-d.png') top right;
	background-size: contain;
	background-repeat: no-repeat;
	@media (min-width: 1024px) {
		padding: 60px 0 0;
	}
	&__item {
		display: flex;
		flex-direction: column;
		margin-bottom: 60px;
		align-items: center;
		justify-content: space-between;
		@media (min-width: 1024px) {
			flex-direction: row;
			align-items: center;
			&:nth-of-type(2) {
				.features__description {
					margin-right: 40px;
				}
			}
			&:nth-of-type(odd) {
				.features__img {
					margin-right: 40px;
				}
			}
			&--reverse {
				flex-direction: row-reverse;
			}
		}
	}
	&__title {
		font-family: Rubik;
		font-weight: 500;
		font-size: 28px;
		line-height: 33px;

		color: #313131;
		margin-bottom: 10px;
		@media (min-width: 768px) {
			font-size: 36px;
			line-height: 43px;
		}
		@media (min-width: 1200px) {
			font-size: 48px;
			line-height: 57px;
		}
	}
	&__text {
		font-family: Rubik;
		font-size: 14px;
		line-height: 130%;
		color: #22272d;
		margin-bottom: 10px;
		@media (min-width: 768px) {
			font-size: 18px;
			line-height: 140%;
			color: #313131;
		}
		@media (min-width: 1200px) {
			font-size: 24px;
			line-height: 140%;
		}
	}
	&__img {
		margin-top: 30px;
		max-width: 100%;
		@media (min-width: 1024px) {
			max-width: 60%;
		}
		&--mobile-only {
			max-width: calc(100% + 30px);
			width: calc(100% + 30px);
			margin: 0 -15px;
			@media (min-width: 425px) {
				display: none;
			}
		}
		&--mobile-none {
			display: none;
			@media (min-width: 425px) {
				display: block;
			}
		}
	}
	&__links {
		width: 100%;
		max-width: 380px;
		margin: 20px auto 0;
		@media (min-width: 1024px) {
			margin: 40px 0 0;
		}
	}
}
