.about{
  padding: 20px 0;
  @media (min-width: 768px) {
    padding: 40px 0;
  }
  &__title{
    font-family: Rubik;
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #313131;

    @media (min-width: 768px) {
      text-align: center;
      font-size: 48px;
      line-height: 57px;
    }
  }
  &__pictures{
    max-width: 100%;
    overflow: hidden;
  }
  &__img{
    &--mobile-only{
      width: 220%;
      margin-left: -65%;
      margin-top: -100px;
      @media (min-width: 768px) {
        display: none;
      }
    }
    &--mobile-none{
      display: none;
      width: 100%;
      margin-top: -100px;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }
  &__reasons{
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__reasons-item{
    margin: 20px 0;
    display: grid;
    grid-template-columns: 70px auto;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    align-items: center;
    @media (min-width: 768px) {
      grid-template-columns: 90px auto;
      grid-template-rows: repeat(2, auto);
      grid-row-gap: 5px;
      grid-column-gap: 5px;
      margin: 20px 10px;
    }
  }
  &__reasons-title{
    grid-area: 1 / 1 / 2 / 3;
    font-family: Rubik;
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;

    color: #313131;
    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 30px;
      grid-area: 1 / 2 / 2 / 3;
    }
    @media (min-width: 1200px) {
      font-size: 36px;
      line-height: 43px;
    }

  }
  &__reasons-icon{
    grid-area: 2 / 1 / 3 / 2;
    width: 69px;
    height: 69px;
    align-self: center;
    @media (min-width: 768px) {
      height: 85px;
      grid-area: 1 / 1 / 3 / 2;
    }
    &--mobile-only{
      width: 59px;
      height: 59px;
      @media (min-width: 768px) {
        display: none;
      }
    }
    &--mobile-none{
      display: none;
      @media (min-width: 768px) {
        display: block;

      }
    }
  }
  &__reasons-text{
    grid-area: 2 / 2 / 3 / 3;
    font-family: Rubik;

    font-size: 14px;
    line-height: 130%;

    color: #22272D;
    @media (min-width: 768px) {
      font-size: 16px;
      color: #313131;
    }
    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 140%;
    }
  }
  &__list{
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
  }
  &__list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    @media (min-width: 768px) {
      width: calc((100% / 3) - 25px);
    }
  }
  &__list-img{
    max-width: 100%;

  }
  &__list-text{
    font-family: Rubik;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    color: #313131;
    margin: 20px auto 0;
    max-width: 330px;

  }

}