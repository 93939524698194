.mobile-footer {
	padding: 20px 0;
	position: relative;
	// &:before {
	// 	content: '';
	// 	background: url('../img/footer-bg-mobile.png');
	// 	background-size: cover;
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	height: 50%;
	// 	z-index: -1;
	// 	background-position: bottom;
	// }

	&__wrapper {
		max-width: 380px;
		margin: 0 auto;
	}
	@media (min-width: 768px) {
		display: none;
	}

	&__block {
		display: flex;
		align-items: center;
	}
	&__links {
		margin-top: 20px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
	&__contact-links {
		display: flex;
		flex-direction: column;
	}
	&__app-links {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.footer {
	padding: 50px 0;
	// background: #b2aaff;
	// footer-bg.png
	display: none;
	@media (min-width: 768px) {
		display: block;
	}
	&__bg-img {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 40%;
		width: 100%;
		z-index: -1;
		max-height: 600px;
		@media (min-width: 768px) {
			max-height: 800px;
		}
	}
	&__bg {
		position: relative;
		// &:before {
		// 	background: url('../img/footer-bg-mobile.png');
		// 	background-size: cover;
		// position: absolute;
		// left: 0;
		// right: 0;
		// bottom: 0;
		// height: 40%;
		// top: 60%;
		// z-index: -1;
		// 	background-position: top;
		// 	@media (min-width: 768px) {
		// 		background: url('/img/footer-bg.png') !important;
		// 		background-position-x: 0%;
		// 		background-position-y: 0%;
		// 		background-repeat: repeat;
		// 		background-size: auto;
		// 		background-size: cover !important;
		// 		background-position: center bottom !important;
		// 	}
		// 	@media (min-width: 1920px) {
		// 		background-position: center 40% !important;
		// 	}
		// }
	}

	&__logo {
		height: 50px;
		margin-right: 20px;
		@media (min-width: 768px) {
			height: 55px;
		}
		@media (min-width: 1200px) {
			height: 75px;
		}
	}
	&__title {
		font-family: Rubik;
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		text-transform: uppercase;
		color: #ffffff;
		@media (min-width: 768px) {
			font-size: 28px;
		}
		@media (min-width: 1200px) {
			font-size: 36px;
		}
	}
	&__text {
		font-family: Rubik;
		font-weight: 500;
		font-size: 18px;
		line-height: 110%;
		color: #ffffff;
		@media (min-width: 1024px) {
			font-size: 24px;
		}
		//@media (min-width: 1200px) {
		//	font-size: 36px;
		//}
	}
	&__contact-link {
		font-family: Rubik;
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #ffffff;
		margin-top: 10px;
		display: flex;
		align-items: center;
	}
	&__link-icon {
		margin-right: 10px;
	}
	&__app-link {
		max-width: 180px;
		width: 100%;
		&:first-of-type {
			margin-right: 20px;
		}
	}
	&__app-icon {
		width: 100%;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__bottom {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.footer__text {
			max-width: 30%;
			@media (min-width: 1024px) {
				max-width: 40%;
			}
		}
		.footer__contact-link {
			margin: 0 8px;
		}
	}
	&__logo-block {
		display: flex;
		align-items: center;
	}
}
